/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./snack-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "./snack-bar.component";
import * as i10 from "@angular/material/snack-bar";
var styles_SnackBarComponent = [i0.styles];
var RenderType_SnackBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SnackBarComponent, data: {} });
export { RenderType_SnackBarComponent as RenderType_SnackBarComponent };
function View_SnackBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 1, 0, currVal_0); }); }
function View_SnackBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 0, 0, currVal_0); }); }
function View_SnackBarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_SnackBarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SnackBarComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SnackBarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissSnackBar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; var currVal_3 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_2, currVal_3); }); }
export function View_SnackBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SnackBarComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SnackBarComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SnackBarComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SnackBarComponent_5)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isText; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isHtml; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.isList; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.showCloseButton; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_SnackBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "snack-bar-component", [], null, null, null, View_SnackBarComponent_0, RenderType_SnackBarComponent)), i1.ɵdid(1, 49152, null, 0, i9.SnackBarComponent, [i10.MatSnackBarRef, i10.MAT_SNACK_BAR_DATA], null, null)], null, null); }
var SnackBarComponentNgFactory = i1.ɵccf("snack-bar-component", i9.SnackBarComponent, View_SnackBarComponent_Host_0, {}, {}, []);
export { SnackBarComponentNgFactory as SnackBarComponentNgFactory };
