<div class="pz-canvas">
  <div class="pz-title-bar">
    <div class="pz-title-container">
      <img src="./assets/images/proZper.png" alt="proZper Logo" height="50px">
    </div>
    <div class="pz-button-container">
      <button mat-mini-fab color="primary" (click)="switchLanguage()">{{"LanguageButtonText" | translate}}</button>
    </div>
  </div>
  <mat-progress-bar *ngIf="waiting || signingUp" mode="indeterminate"></mat-progress-bar>

  <div class="pz-content">
    <div class="pz-input-panel pz-panel-width">

      <div *ngIf="!waiting && validEntry && step === 0 && failureCode === 0" class="pz-step-title">
        {{"Step.RegisterUser" | translate}}
      </div>
      <div *ngIf="!waiting && step === 1 && failureCode === 0" class="pz-step-title">
        {{"Step.RegisterSuccess" | translate}}
      </div>
      <div *ngIf="!waiting && step === 1 && failureCode !== 0" class="pz-step-title">
        {{"Step.RegisterFailed" | translate}}
      </div>

      <div *ngIf="!validEntry" class="pz-step-title">
        <p class="pz-info-text">{{"Error.ImproperEntry" | translate}}</p>
      </div>

      <div class="pz-input-content">
        <form [formGroup]="registrationForm" *ngIf="!waiting && validEntry && step === 0 && failureCode === 0"
          class="pz-input-form" (ngSubmit)="signUp()">
          <div class="pz-form-header">{{displayFullName()}}</div>
          <mat-form-field>
            <mat-label>{{"Label.PhoneNumber" | translate}}</mat-label>
            <input matInput type="text" mask="000-000-0000" [dropSpecialCharacters]="true" formControlName="phone">
            <!--mat-hint *ngIf="!phoneCtrl.hasError('pattern') && !phoneCtrl.hasError('required')">
              {{"Hint.PhoneNumber" | translate}}
            </mat-hint-->
            <mat-error *ngIf="phone.hasError('Mask error') && !phone.hasError('required')">
              {{"Error.IncorrectPhoneNumber" | translate}}
            </mat-error>
            <mat-error *ngIf="phone.hasError('required')">
              {{"Error.MissingPhoneNumber" | translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{"Label.Username" | translate}}</mat-label>
            <input #userNameInp matInput type="text" autocapitalize="none" formControlName="username">
            <mat-error *ngIf="username.hasError('pattern') && !username.hasError('required')">
              {{"Error.IncorrectUsernamePattern" | translate}}
            </mat-error>
            <mat-error *ngIf="username.hasError('required')">
              {{"Error.MissingUsername" | translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{"Label.Password" | translate}}</mat-label>
            <input matInput type="password" formControlName="password">
            <mat-error *ngIf="password.hasError('pattern') && !password.hasError('required')">
              {{"Error.IncorrectPasswordPattern" | translate}}
            </mat-error>
            <mat-error *ngIf="password.hasError('required')">
              {{"Error.MissingPassword" | translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{"Label.ConfirmPassword" | translate}}</mat-label>
            <input matInput type="password" formControlName="confirmPassword">
            <mat-error *ngIf="confirmPassword.hasError('pattern') && !confirmPassword.hasError('required')">
              {{"Error.IncorrectPasswordPattern" | translate}}
            </mat-error>
            <mat-error *ngIf="confirmPassword.hasError('required')">
              {{"Error.MissingConfirmPassword" | translate}}  
            </mat-error>
          </mat-form-field>

          <div class="pz-button-container">
            <button type="submit" mat-fab [color]="'primary'" [disabled]="signingUp">
              <mat-icon>checkmark</mat-icon>
            </button>
          </div>
        </form>

        <div *ngIf="!waiting && validEntry && step === 1" class="pz-input-form">
          <p #endMessage class="pz-info-text"></p>
          <p *ngIf="failureCode === 0" class="pz-info-text">
            {{"EndMessage.Code00.WebApplication" | translate}}
            <span><a href="https://app.prozper.io">app.proZper.io</a> </span>
            {{"EndMessage.Code00.MobileApplication" | translate}}
          </p>
          <div *ngIf="failureCode === 1">
            <p class="pz-info-text">{{"EndMessage.Code01.Text1" | translate}}</p>
            <p class="pz-info-text">{{"EndMessage.Code01.Text2" | translate}}</p>
          </div>
          <div *ngIf="failureCode === 2">
            <p class="pz-info-text">
              {{"EndMessage.Code02.Text1" | translate}}
              <span *ngIf="errorMessage">
                {{"EndMessage.DueTo" | translate}} {{errorMessage}}{{"EndMessage.FullStop" | translate}}
              </span>
            </p>
            <p class="pz-info-text">{{"EndMessage.Code02.Text2" | translate}}</p>  
          </div>
          <div *ngIf="failureCode === 90">
            <p class="pz-info-text">{{"EndMessage.Code90.Text1" | translate}}</p>      
            <p class="pz-info-text">{{"EndMessage.Code90.Text2" | translate}}</p>      
          </div>
          <div *ngIf="failureCode === 99">
            <p class="pz-info-text">{{"EndMessage.Code99.Text1" | translate}}</p>      
            <p class="pz-info-text">{{"EndMessage.Code99.Text2" | translate}}</p>      
          </div>
        </div>

      </div>
    </div>
  </div>

</div>