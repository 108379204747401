import * as tslib_1 from "tslib";
import * as Sentry from "@sentry/browser";
import { OnInit, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../environments/environment';
import { ApiService } from '../services/api.service';
import { SnackBarService } from '../services/snack-bar.service';
var Locale;
(function (Locale) {
    Locale["Thai"] = "th";
    Locale["English"] = "en";
})(Locale || (Locale = {}));
;
const UserNameRex = /^[0-9A-Za-z.\d]+$/;
const PasswordRex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/;
export class proZperSignUpComponent {
    // Constructor ___________________________________________________________________________________
    constructor(location, translate) {
        this.location = location;
        this.translate = translate;
        this.waiting = true;
        this.validEntry = true;
        this.step = 0;
        this.signingUp = false;
        this.endMessage = null;
        this.failureCode = 0;
        this.errorMessage = null;
        this.registrationForm = new FormGroup({
            phone: new FormControl('', [Validators.required]),
            username: new FormControl('', [Validators.required, Validators.pattern(UserNameRex)]),
            password: new FormControl('', [Validators.required, Validators.pattern(PasswordRex)]),
            confirmPassword: new FormControl('', [Validators.required, Validators.pattern(PasswordRex)])
        });
        this.nameTh = null;
        this.surnameTh = null;
        this.nameEn = null;
        this.surnameEn = null;
        this.phoneNumber = null;
        this.email = null;
        this.defaultLocale = 'th';
        this.errorCount = 0;
        this.translate.setDefaultLang(this.defaultLocale);
    }
    get phone() { return this.registrationForm.get('phone'); }
    ;
    get username() { return this.registrationForm.get('username'); }
    ;
    get password() { return this.registrationForm.get('password'); }
    ;
    get confirmPassword() { return this.registrationForm.get('confirmPassword'); }
    ;
    // Lifecycle Hooks _______________________________________________________________________________
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Extract and verify invitation id in URL path
            this.validEntry = this.parseParameters(this.location.path(true));
            this.translate.use(this.defaultLocale);
            if (this.validEntry) {
                try {
                    let result = yield ApiService.checkInvitation(this.invitationId);
                    if (result === 'NotFound') {
                        this.step = 1;
                        this.failureCode = 90;
                    }
                }
                catch (error) {
                    console.log(error);
                    this.step = 1;
                    this.failureCode = 99;
                    if (!environment.test) {
                        Sentry.withScope(scope => {
                            scope.setExtra('function', 'ngOnInit');
                            scope.setExtra('params', JSON.stringify({
                                invitationId: this.invitationId,
                                name: this.nameTh,
                                surname: this.surnameTh,
                                email: this.email
                            }, null, 2));
                            Sentry.captureException(error);
                        });
                    }
                }
            }
            this.waiting = false;
        });
    }
    // View Controllers ______________________________________________________________________________
    //................................................................................................
    signUp() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let validPassword = this.password.value === this.confirmPassword.value;
            let validUser;
            if (this.registrationForm.invalid) {
                SnackBarService.showWarning(this.translate.instant('Warning.IncorrectOrIncompleteEntry'));
            }
            else {
                validUser = this.phoneNumber === this.phone.value;
            }
            if (!validPassword) {
                SnackBarService.showWarning(this.translate.instant('Warning.MismatchedPassword'));
            }
            if (validUser !== undefined && !validUser) {
                if (this.errorCount < 3) {
                    SnackBarService.showError(this.translate.instant('Error.PhoneNumberNotMatched'));
                    this.errorCount += 1;
                }
                else {
                    this.failureCode = 1;
                    this.step = 1;
                }
            }
            if (this.registrationForm.valid && validPassword && validUser) {
                let intlPhone = '+66' + this.phoneNumber.slice(1);
                let username = this.username.value;
                let password = this.password.value;
                this.phone.disable({ onlySelf: true });
                this.username.disable({ onlySelf: true });
                this.password.disable({ onlySelf: true });
                this.confirmPassword.disable({ onlySelf: true });
                this.signingUp = true;
                try {
                    yield ApiService.signUpWithInvitation(username, password, this.nameTh, this.surnameTh, this.email, intlPhone, this.invitationId);
                }
                catch (error) {
                    this.signingUp = false;
                    if (error.message === 'Cognito user already exists') {
                        let message = this.translate.currentLang === Locale.Thai
                            ? `มีบุคคลอื่นใช้ชื่อผู้ใช้ ${username} แล้ว กรุณาเปลี่ยนชื่อผู้ใช้`
                            : `${username} has already been used. Please change username.`;
                        SnackBarService.showWarning(message);
                        this.username.enable({ onlySelf: true });
                        this.password.enable({ onlySelf: true });
                        this.confirmPassword.enable({ onlySelf: true });
                        this.userNameInp.nativeElement.focus();
                    }
                    else {
                        console.log('Error: ', error);
                        this.errorMessage = error.message !== undefined ? error.message : null;
                        this.failureCode = 2;
                        this.step = 1;
                        if (!environment.test) {
                            Sentry.withScope(scope => {
                                scope.setExtra('function', 'signUp');
                                scope.setExtra('params', JSON.stringify({
                                    invitationId: this.invitationId,
                                    name: this.nameTh,
                                    surname: this.surnameTh,
                                    username: username,
                                    email: this.email
                                }, null, 2));
                                Sentry.captureException(error);
                            });
                        }
                    }
                    return;
                }
                this.signingUp = false;
                this.step = 1;
            }
        });
    }
    //................................................................................................
    switchLanguage() {
        let currentLanguage = this.translate.currentLang;
        this.translate.use(currentLanguage === Locale.Thai ? Locale.English : Locale.Thai);
    }
    displayFullName() {
        let fullNameTh = `${this.nameTh} ${this.surnameTh}`;
        let fullNameEn = this.nameEn ? `${this.nameEn} ${this.surnameEn}` : fullNameTh;
        return this.translate.currentLang === Locale.Thai ? fullNameTh : fullNameEn;
    }
    // Data Processing _______________________________________________________________________________
    //................................................................................................
    parseParameters(path) {
        let validEntry = false;
        let index = path.indexOf('=');
        let url = path.split('?');
        let params = url[1].split('&');
        let data = params[0].slice(5);
        let language = params[1].slice(9);
        if (index > -1) {
            let params = JSON.parse(decodeURI(window.atob(data)));
            this.invitationId = params.n !== undefined ? params.n : null;
            this.nameTh = params.m !== undefined ? params.m : null;
            this.surnameTh = params.r !== undefined ? params.r : null;
            this.nameEn = params.o !== undefined ? params.o : null;
            this.surnameEn = params.t !== undefined ? params.t : null;
            this.email = params.i !== undefined ? window.atob(params.i) : null;
            this.phoneNumber = params.h !== undefined ? window.atob(params.h) : null;
            this.defaultLocale = language;
            if (this.invitationId && this.nameTh && this.surnameTh && this.email && this.phone) {
                validEntry = true;
            }
        }
        return validEntry;
    }
}
