import * as Sentry from "@sentry/browser";

import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule, MatSnackBar } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NgxMaskModule } from 'ngx-mask';

import { ApiService } from '../services/api.service';
import { SnackBarService } from '../services/snack-bar.service';

import { proZperSignUpComponent } from './app.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
};

Sentry.init({
  dsn: "https://f34d141b84294968884573bd4ac12222@sentry.io/1857780",
  release: "prozper-signup@3.0.0"
});


@NgModule({
  declarations: [
    proZperSignUpComponent,
    SnackBarComponent
  ],
  entryComponents: [
    SnackBarComponent
  ],
  imports: [
    BrowserModule, BrowserAnimationsModule,
    FormsModule, ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule, MatStepperModule, MatIconModule, MatInputModule,
    MatProgressBarModule, MatSnackBarModule, MatTooltipModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [ HttpClient ]
      }
    }),

    NgxMaskModule.forRoot()
  ],
  providers: [
    ApiService, SnackBarService
  ],
  bootstrap: [ proZperSignUpComponent ]
})
export class AppModule {
  constructor(private httpClient: HttpClient, private snackBar: MatSnackBar) {
    ApiService.initialize(this.httpClient);
    SnackBarService.initialize(this.snackBar);
  }
}