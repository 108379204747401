import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const SharedAPIKey = 'imZTdcmi2o6ADF9hSz0gpazSwVIRQlUPa0kBKcrK';
const SharedAPIGateway = 'https://toqyw9a5g3.execute-api.ap-southeast-1.amazonaws.com/prod';
const CheckInvitationAPI = `${SharedAPIGateway}/user/check-invitation`;
const SignUpWithInvitationAPI = `${SharedAPIGateway}/user/sign-up`;


@Injectable()
export class ApiService {
  private static http: HttpClient;

  public static initialize(http: HttpClient) {
    this.http = http;
  }

  //------------------------------------------------------------------------
  // Method: checkInvitation - Check the existence of invitation file in S3
  //------------------------------------------------------------------------
  public static checkInvitation(invitationId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let body = { invitationId: invitationId };
      let options = { headers: new HttpHeaders({ 'X-Api-Key': SharedAPIKey }) };
      this.http.post(CheckInvitationAPI, body, options).subscribe((response: any) => {
        if (response && response.errorMessage) {
          reject(new Error(response.errorMessage));
        } else {
          resolve(response);
        }
      }, (error) => {
        reject(error);
      });
    });
  }

  //------------------------------------------------------------------------------------------------------
  // Method: signUpWithInvitation - Sign up a new cognito user using information from an invitation in S3
  //------------------------------------------------------------------------------------------------------
  public static signUpWithInvitation(userName: string, password: string, name: string, surname: string,
    email: string, phone: string, invitationId: string): Promise<any> {

    return new Promise((resolve, reject) => {
      let body = {
        username: userName,
        password: password,
        name: name,
        family_name: surname,
        email: email,
        phone_number: phone,
        invitationId: invitationId
      };
      let options = { headers: new HttpHeaders({ 'X-Api-Key': SharedAPIKey }) };
      this.http.post(SignUpWithInvitationAPI, body, options).subscribe((response: any) => {
        if (response && response.errorMessage) {
          reject(new Error(response.errorMessage));
        } else {
          resolve(response);
        }
      }, (error) => {
        reject(error);
      });
    });
  }
}