import { Component, Inject } from '@angular/core';

import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'snack-bar-component',
  templateUrl: 'snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {
  isHtml = false;
  isText = false;
  isList = false;
  messages: any;
  showCloseButton: boolean;

  constructor(private snackBarRef: MatSnackBarRef<SnackBarComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    if (typeof data.messages === 'string') {
      if (data.messages.charAt(0) === '<') {
        this.isHtml = true;
        this.isText = false;
      } else {
        this.isHtml = false;
        this.isText = true;
      }
      this.isList = false;
      this.messages = data.messages;
    } else {
      this.isHtml = false;
      if (data.messages.length === 1) {
        this.isText = true;
        this.isList = false;
        this.messages = data.messages[0];
      } else {
        this.isText = false;
        this.isList = true;
        this.messages = data.messages;  
      }
    }
    this.showCloseButton = data.showCloseButton;
  }

  dismissSnackBar() {
    this.snackBarRef.dismiss();
  }
}