import { Injectable } from '@angular/core';

import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

import { SnackBarComponent } from '../app/snack-bar/snack-bar.component';

@Injectable()
export class SnackBarService {
  private static snackBar: MatSnackBar;
  private static snackBarRef: MatSnackBarRef<SnackBarComponent> = null;
  private static informationConfig: MatSnackBarConfig = {
    duration: 5000,
    horizontalPosition: 'right',
    panelClass: ['pz-information-snackbar'],
    data: { message: null, showCloseButton: false }
  };
  private static successConfig: MatSnackBarConfig = {
    duration: 5000,
    horizontalPosition: 'right',
    panelClass: ['pz-success-snackbar'],
    data: { message: null, showCloseButton: false }
  };
  private static warningConfig: MatSnackBarConfig = {
    duration: 9000,
    horizontalPosition: 'right',
    panelClass: ['pz-warning-snackbar'],
    data: { message: null, showCloseButton: false }    
  }
  private static errorConfig: MatSnackBarConfig = {
    duration: 9000,
    horizontalPosition: 'right',
    panelClass: ['pz-error-snackbar'],
    data: { messages: null, showCloseButton: true }
  };

  // Service Methods _______________________________________________________________________________

  public static initialize(snackBar: MatSnackBar) {
    this.snackBar = snackBar;
  }

  public static showInformation(messages: string | Array<string>, duration = 5000, showCloseButton = false) {
    this.informationConfig.duration = duration;
    this.informationConfig.data.messages = messages;
    this.informationConfig.data.showCloseButton = showCloseButton;
    this.snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, this.informationConfig);    
  }

  public static showSuccess(messages: string | Array<string>, duration = 5000, showCloseButton = false) {
    this.successConfig.duration = duration;
    this.successConfig.data.messages = messages;
    this.successConfig.data.showCloseButton = showCloseButton;
    this.snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, this.successConfig);
  }

  public static showWarning(messages: string | Array<string>, duration = 5000, showCloseButton = false) {
    this.warningConfig.duration = duration;
    this.warningConfig.data.messages = messages;
    this.warningConfig.data.showCloseButton = showCloseButton;
    this.snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, this.warningConfig);
  }

  public static showError(messages: string | Array<string>, duration = 9000, showCloseButton = true) {
    this.errorConfig.duration = duration;
    this.errorConfig.data.messages = messages;
    this.errorConfig.data.showCloseButton = showCloseButton;
    this.snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, this.errorConfig);
  }

  public static dismiss() {
    if (this.snackBarRef !== null) {
      this.snackBarRef.dismiss();
      this.snackBarRef = null;
    }
  }
}