import * as Sentry from "@sentry/browser";
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApiService } from '../services/api.service';
import { SnackBarService } from '../services/snack-bar.service';
export function createTranslateLoader(http) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
;
Sentry.init({
    dsn: "https://f34d141b84294968884573bd4ac12222@sentry.io/1857780",
    release: "prozper-signup@3.0.0"
});
const ɵ0 = (createTranslateLoader);
export class AppModule {
    constructor(httpClient, snackBar) {
        this.httpClient = httpClient;
        this.snackBar = snackBar;
        ApiService.initialize(this.httpClient);
        SnackBarService.initialize(this.snackBar);
    }
}
export { ɵ0 };
